import { createRouter, createWebHistory } from "vue-router"
import store from '@/store'

const routes = [
    {
        path: "/",
        name: "home",
        redirect: "/app"
    },
    {
        path: "/tologin",
        name: "login",
        component: ()=>import("@/views/login/login.vue"),
    },
    {
        path: "/ui/tty",
        name: "uitty",
        component: ()=>import("@/views/app/webshell-fs.vue"),
    },
    {
        path: "/app",
        name: "app",
        redirect: "/app/dashboard",
        component: ()=>import("@/views/app/app.vue"),
        children: [
            {
                path: "webshell",
                name: "webshell",
                component: ()=>import("@/views/app/web-shell.vue"),
            },
            {
                path: "yaml-create",
                name: "yaml-create",
                component: ()=>import("@/views/app/yaml-create.vue"),
            },
            {
                path: "dashboard",
                name: "dashboard",
                component: ()=>import("@/views/dashboard/dashboard.vue"),
            },
            {
                path: "deployments",
                name: "deployments",
                component: ()=>import("@/views/deployments/deployments.vue"),
            },
            {
                path: "statefulsets",
                name: "statefulsets",
                component: ()=>import("@/views/deployments/deployments.vue"),
            },
            {
                path: "deployments-form",
                name: "deployments-form",
                component: ()=>import("@/views/deployments/deployments-form.vue"),
            },
            {
                path: "statefulsets-form",
                name: "statefulsets-form",
                component: ()=>import("@/views/deployments/deployments-form.vue"),
            },
            {
                path: ":apptype(deployments|statefulsets)/:id",
                name: "deployments-id",
                component: ()=>import("@/views/deployments/deployments-id.vue"),
                redirect: (to)=>{
                    return {path: "/app/"+ to.params.apptype +"/"+ to.params.id +"/detail"}
                },
                children: [
                    {
                        path: "detail",
                        name: "deployments-detail",
                        component: ()=>import("@/views/deployments/deployments-detail.vue"),
                    },
                    {
                        path: "edit",
                        name: "deployments-edit",
                        component: ()=>import("@/views/deployments/deployments-form.vue"),
                    },
                    {
                        path: "pod",
                        name: "deployments-pod",
                        component: ()=>import("@/views/deployments/pod.vue"),
                    },
                    {
                        path: "pod-webshell/:pod",
                        name: "deployments-podwebshell",
                        component: ()=>import("@/views/deployments/pod-webshell.vue"),
                    },
                    {
                        path: "files",
                        name: "deployments-files",
                        component: ()=>import("@/views/deployments/files.vue"),
                    },
                    {
                        path: "domain",
                        name: "deployments-domain",
                        component: ()=>import("@/views/deployments/deployments-domain.vue"),
                    },
                    {
                        path: "domain-form/:domain*",
                        name: "deployments-domain-form",
                        component: ()=>import("@/views/deployments/deployments-domainform.vue"),
                    },
                    {
                        path: "events",
                        name: "deployments-events",
                        component: ()=>import("@/views/deployments/events.vue"),
                    },
                ]
            },
            {
                path: "mirror",
                name: "mirror",
                component: ()=>import("@/views/mirror/mirror.vue"),
            },
            {
                path: "mirror-form/:id*",
                name: "mirror-form",
                component: ()=>import("@/views/mirror/mirror-form.vue"),
            },
            {
                path: "configmap",
                name: "configmap",
                mark: "configmap",
                component: ()=>import("@/views/configmap/configmap.vue"),
            },
            {
                path: "configmap-detail/:id",
                name: "configmap-detail",
                mark: "configmap",
                component: ()=>import("@/views/configmap/configmap-detail.vue"),
            },
            {
                path: "configmap-form/:id*",
                name: "configmap-form",
                mark: "configmap",
                component: ()=>import("@/views/configmap/configmap-form.vue"),
            },
            {
                path: "secret",
                name: "certificate",
                component: ()=>import("@/views/certificate/crt.vue"),
            },
            {
                path: "secret-form/:id*",
                name: "certificate-form",
                component: ()=>import("@/views/certificate/crt-form.vue"),
            },
            {
                path: "persistentvolumeclaims",
                name: "persistentvolumeclaims",
                component: ()=>import("@/views/storage/storage.vue"),
            },
            {
                path: "service",
                name: "service",
                component: ()=>import("@/views/server/server.vue"),
            },
            {
                path: "service-form/:id*",
                name: "service-form",
                component: ()=>import("@/views/server/server-form.vue"),
            },
            {
                path: "ingress",
                name: "ingress",
                component: ()=>import("@/views/domain/domain.vue"),
            },
            {
                path: "ingress-form/:id*",
                name: "ingress-form",
                component: ()=>import("@/views/domain/domain-form.vue"),
            },
            {
                path: "helm",
                name: "helm",
                component: ()=>import("@/views/helm/helm.vue"),
            },
            {
                path: "cronjob",
                name: "cronjob",
                component: ()=>import("@/views/cronjob/cronjob.vue"),
            },
            {
                path: "job",
                name: "job",
                component: ()=>import("@/views/cronjob/cronjob.vue"),
            },
            {
                path: "cronjob-form/:id*",
                name: "cronjob-form",
                component: ()=>import("@/views/cronjob/cronjob-form.vue"),
            },
            {
                path: "job-form",
                name: "job-form",
                component: ()=>import("@/views/cronjob/cronjob-form.vue"),
            },
            {
                path: "cronjob-detail/:id",
                name: "cronjob-detail",
                component: ()=>import("@/views/cronjob/cronjob-detail.vue"),
            },
            {
                path: "job-detail/:id",
                name: "job-detail",
                component: ()=>import("@/views/cronjob/cronjob-detail.vue"),
            },
            {
                path: "cronjob-log",
                name: "cronjob-log",
                component: ()=>import("@/views/cronjob/cronjob-log.vue"),
            },
            {
                path: "resource",
                name: "resource",
                component: ()=>import("@/views/resource/resource.vue"),
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if(store.state.namespace.length > 0) {
        next()
    }else{
        if(to.name=='login'||to.name=='uitty'){ next(); return;}
        store.dispatch("getNameSpace").then(()=>{
            next();
        })
    }
})
export default router
